.login {

  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  background: $secondary;
  position: relative;

  > * {
    position: relative;
    z-index: 2;
  }

  &:after {
    content: url('../img/templates/logo_white_solo.svg');
    display: block;
    position: absolute;
    right: -10%;
    bottom: -10%;
    width: 50%;
    opacity: .15;
    z-index: 1;
  }

  &__form {
    background: #fff;
    border-top: 3px solid $primary;
    width: 380px;
    padding: 30px;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }

    img {
      max-width: 220px;
      margin: 0 auto 40px;
      display: block;
    }
  }
}