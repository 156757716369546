html, body {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    overflow-x: hidden;
  }
}

.view {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.col-sm-5 {
  height: 100%;
}

section {
  padding: 30px;
  max-width: 960px;
}

strong {
  font-weight: 700;
}

td {
  border: 1px solid $grey--light;
}

img {
  max-width: 100%;

}
