.content {

  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 260px;

  @include media-breakpoint-down(sm) {
    margin-left: 0;
  }

  &__header {
    background: $secondary;
    padding: 45px 30px 35px;
    display: flex;
    align-items: center;

    h1 {
      font-weight: 800;
      margin: 0;
      padding-right: 20px;
    }

    a {
      color: $gunmetal;
      border-bottom: 1px solid $gunmetal;

      &:hover {
        color: $gunmetal;
        border-bottom: 1px solid transparent;
        text-decoration: none;
      }
    }
  }

  > .row {
    flex: 1;
  }

  &__list {
    border-right: 1px solid $border-color;
    height: 100%;
    background: $grey--light;

    a {
      color: $body-color;
      padding: 15px 30px;
      border-bottom: 1px solid $border-color;
      border-right: 3px solid transparent;

      &:hover, &.active {
        text-decoration: none;
        border-right-color: $primary;
      }

      span {
        font-size: 14px;
        max-height: 20px;
        overflow: hidden;
        display: block;
      }

      small {
        display: flex;
        align-items: baseline;

        span {
          margin-left: 10px;
        }
      }
    }
  }

  &__edit {
    padding: 15px 30px 60px 0;
    @include media-breakpoint-down(sm) {
      padding: 30px 15px;
    }
  }
}