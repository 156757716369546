.burger {
  width: 25px;
  display: none;

  @include media-breakpoint-down(sm) {
    display: block;
  }

  &-line {
    background-color: #fff;

    display: block;
    width: 100%;
    height: 2px;
    margin-top: 6px;
    transform: none;

    &:first-child {
      margin-top: 0;
    }

    &:not(:nth-child(2)) {
      transition: $transition-base;
    }
  }
}

.opened .burger {
  &-line {
    &:nth-child(1) {
      transform: rotate(45deg);
      position: relative;
      top: 10px;
      height: 2px;
    }

    &:nth-child(2) {
      width: 0 !important;
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
      position: relative;
      top: -6px;
      height: 2px;
    }
  }
}