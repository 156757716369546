@import "sidebar-burger";

.sidebar {

  display: flex;
  flex-direction: column;
  background: $gunmetal;
  color: #fff;
  width: 260px;
  height: 100vh;
  position: fixed;
  z-index: 1000;

  @include media-breakpoint-down(sm) {
    width: 100%;
    position: relative;
    height: auto;
  }

  &.opened {
    .sidebar__menu, .sidebar__footer {
      display: block;
    }
  }

  &__logo {
    padding: 45px 15px;

    @include media-breakpoint-down(sm) {
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    img {
      max-width: 70%;
      @include media-breakpoint-down(sm) {
        height: 30px;
      }
    }
  }

  &__mobile {
    flex: 1;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
      position: absolute;
      top: 49px;
      width: 100%;
      background: $gunmetal;

      .sidebar__menu {
        padding-top: 20px;
      }

      .sidebar__menu, .sidebar__footer {
        display: none;
      }
    }
  }

  &__menu {
    flex: 1;
    border-bottom: 1px solid lighten($gunmetal, 10%);

    small {
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $primary;
      margin: 50px 0 20px;
      display: block;
      padding: 0 15px;

      @include media-breakpoint-down(sm) {
        margin: 0 0 10px;
      }
    }

    ul {

      &.w-borders {
        border-top: 1px solid lighten($gunmetal, 10%);
        border-bottom: 1px solid lighten($gunmetal, 10%);
      }

      li {

        &.active {
          a {
            background: lighten($gunmetal, 10%);
            color: #fff;
          }
        }

        a {
          padding: 10px 15px;
          display: block;
          color: $grey;

          &:hover {
            text-decoration: none;
            color: #fff;
          }
        }
      }

    }
  }

  &__footer {
    text-align: center;

    a {
      color: $grey;
      font-size: 14px;
      padding: 10px;
      display: block;

      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}