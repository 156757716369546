.homepage {

  padding: 30px;
  max-width: 1024px;

  &__btn {
    background: $primary;
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 180px;
    text-align: center;

    font-size: 20px;
    padding: 30px;
    font-weight: 700;

    margin-bottom: 30px;

    position: relative;

    p {
      margin: 0;
      z-index: 2;
      position: relative;
    }

    span {
      position: absolute !important;
      bottom: -10px;
      right: -10px;
      top: auto !important;
      font-size: 100px;
      opacity: .2;
      z-index: 1;
    }

    &:hover {
      text-decoration: none;
      color: #fff !important;
      background: lighten($primary, 10%);

      span {
        opacity: .6;
      }
    }
  }
}