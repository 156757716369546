// Colors
$greeny-blue: #5cbfa5;
$ocean-green: #387c6a;
$silver: #c6cfcc;
$gunmetal: #4e5758;
$grey: #99a4a5;
$grey--light: #f3f3f3;
$slate-grey: #5f6e6f;
$ice: #e1f0ec;
$border-color: darken($grey--light, 10%);

// Bootstrap
$primary: $greeny-blue;
$primary--dark: $ocean-green;
$primary--light: $ice;
$secondary: $silver;
$dark: $gunmetal;

$font-family-base: 'Work Sans', sans-serif;
$font-weight-base: 300;
$body-color: $gunmetal;
$body-bg: #fff;

$input-border-radius: 0;

$btn-font-size: 14px;
$btn-line-height: 47px;
$btn-padding-y: 0;
$btn-padding-x: 30px;
$btn-border-radius: 6px;

$h1-font-size: 36px;
$h1-font-weight: 800;
$h2-font-size: 30px;
$h2-font-weight: 800;
$h3-font-size: 26px;
$h3-font-weight: 700;
$h4-font-size: 14px;