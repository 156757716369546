.btn {

  border: none;
  padding-bottom: 3px;

  &:hover, &:focus, &:active {
    outline: none;
  }

  &-primary {
    color: #fff;
    box-shadow: inset 0 -3px $primary--dark;

    &:hover, &:focus, &:active {
      background: $primary;
      color: #fff !important;
    }
  }

  &-dark {
    background: $slate-grey;
    box-shadow: inset 0 -3px $dark;
  }

  &-secondary {
    box-shadow: inset 0 0 0 1px $dark;
    background: transparent;

    &:hover, &:focus, &:active {
      background: $dark;
      color: #fff;
    }
  }

  @include media-breakpoint-down(xs) {
    display: block;
    width: 100%;
  }
}

.btn + .btn {
  margin: 0 0 0 2em;

  @include media-breakpoint-down(xs) {
    margin: 2em 0 0 0;
  }
}
